import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import truncateHtml from "truncate-html"
import Img from "gatsby-image/withIEPolyfill"

import Link from "../link"

const Container = styled.article``

const Heading = styled.h3.attrs({ className: `styled-p-leading` })`
  a {
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.4;
    }
  }
`

const FigureLink = styled(Link)`
  display: block;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`

const Illustration = styled.figure`
  width: 70%;
  height: 0;
  padding-bottom: 60%;
  margin-bottom: 3em;
  position: relative;

  @media ${(props) => props.theme.smallDown} {
    width: 68%;
    margin: 0 auto 2em;
    position: relative;
    left: -3%;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const Image = styled.figure`
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  margin-bottom: 3em;
  position: relative;

  @media ${(props) => props.theme.smallDown} {
    width: 92%;
    margin-bottom: 2em;
    position: relative;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const Excerpt = styled.p.attrs({ className: `styled-p-leading` })`
  opacity: 0.6;
`

const Meta = styled.footer.attrs({ className: `styled-p` })`
  margin-top: 1em;
  display: flex;
  color: ${(props) => props.theme.colorPurpleLight};

  time {
    white-space: nowrap;
  }

  a {
    &:hover {
      color: ${(props) => props.theme.colorPurple};
    }

    &:active {
      color: ${(props) => props.theme.colorPurpleLight};
    }
  }

  ul {
    position: relative;
    padding-left: 1.4em;

    &::before {
      content: "";
      width: 0.4em;
      height: 0.4em;
      position: absolute;
      top: 0.45em;
      left: 0.5em;
      background-color: currentColor;
      border-radius: 50%;
      opacity: 0.5;
    }

    li {
      display: inline;

      &:not(:last-child) {
        &::after {
          content: ", ";
        }
      }
    }
  }
`

const FollowLink = styled.p.attrs({ className: `styled-p-leading` })`
  margin-top: 1.3em;
`

const Figure = ({ illustration, image, title }) => {
  if (illustration) {
    return (
      <Illustration>
        <img
          src={`/product-illustrations/${illustration}-inverted.svg`}
          alt={title}
          role="presentation"
        />
      </Illustration>
    )
  }

  if (image && image.localFile) {
    return (
      <Image>
        <Img
          fluid={image.localFile.childImageSharp.fluid}
          alt={title}
          objectPosition="center center"
          objectFit="cover"
        />
      </Image>
    )
  }

  return null
}

Figure.propTypes = {
  illustration: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
}

const Blurb = ({
  data: {
    title,
    titleLevel,
    text,
    linkUrl,
    linkText,
    illustration,
    image,
    date,
    categories,
  },
}) => {
  return (
    <Container>
      {(illustration || (image && image.localFile)) && (
        <React.Fragment>
          {linkUrl ? (
            <FigureLink to={linkUrl}>
              <Figure illustration={illustration} image={image} title={title} />
            </FigureLink>
          ) : (
            <Figure illustration={illustration} image={image} title={title} />
          )}
        </React.Fragment>
      )}

      {linkUrl ? (
        <Heading as={titleLevel}>
          <Link to={linkUrl} dangerouslySetInnerHTML={{ __html: title }} />
        </Heading>
      ) : (
        <Heading as={titleLevel} dangerouslySetInnerHTML={{ __html: title }} />
      )}

      {text && (
        <Excerpt>
          {truncateHtml(text, { length: 9999, stripTags: true })}
        </Excerpt>
      )}

      {(date || (categories && categories.length)) && (
        <Meta>
          <time dateTime={date}>{date}</time>

          {categories && categories.length && (
            <ul>
              {categories.map((cat, i) => (
                <li key={i} className="styled-p">
                  <Link to={cat.url}>{cat.name}</Link>
                </li>
              ))}
            </ul>
          )}
        </Meta>
      )}

      {linkUrl && linkText && (
        <FollowLink>
          <Link to={linkUrl} className="styled-a">
            {linkText}
          </Link>
        </FollowLink>
      )}
    </Container>
  )
}

Blurb.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Blurb
