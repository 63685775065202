import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"

const Container = styled.section``

const Logos = styled.div``

const Logo = styled.figure`
  width: 100%;
  height: 0;
  padding-bottom: 33%;
  position: relative;

  .gatsby-image-wrapper {
    width: 90%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const CustomersAll = ({ data: { edges } }) => {
  if (!edges || !edges.length) return null

  edges = edges.filter(
    ({ node }) =>
      node.acf.customerLogotype && node.acf.customerLogotype.localFile
  )

  if (!edges.length) return null

  return (
    <Container>
      <Grid>
        <h2 className="is-vhidden">Customers</h2>

        <Logos>
          <Row mq={{ xsmallDown: { pushY: 1.5, pullY: 6 } }}>
            {edges.map(({ node }, i) => (
              <Cell
                key={i}
                size={3}
                pushX={i % 6 !== 0 ? 1 : 0}
                pushY={i >= 6 ? 1 : 0}
                mq={{
                  smallDown: {
                    size: 5,
                    pushX: i % 4 !== 0 ? 1 : 0,
                    pushY: i >= 4 ? 2 : 0,
                  },
                  xsmallDown: {
                    size: 7,
                    pushX: i % 3 !== 0 ? 1 : 0,
                    pushY: i >= 3 ? 3 : 0,
                  },
                  xxsmallDown: {
                    size: 11,
                    pushX: i % 2 !== 0 ? 1 : 0,
                    pushY: i >= 2 ? 3 : 0,
                  },
                }}
              >
                <Logo>
                  <Img
                    objectFit="contain"
                    fluid={
                      node.acf.customerLogotype.localFile.childImageSharp.fluid
                    }
                    title={node.acf.customerName}
                    alt={node.acf.customerName}
                    style={{
                      position: `absolute`,
                    }}
                  />
                </Logo>
              </Cell>
            ))}
          </Row>
        </Logos>
      </Grid>
    </Container>
  )
}

CustomersAll.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CustomersAll
