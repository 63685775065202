import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

import Link from "../link"
import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"

const Container = styled.section`
  position: relative;
  background-color: ${(props) => props.theme.colorGreyLight};

  ${(props) =>
    props.colorTheme === `dark` &&
    css`
      background-color: ${(props) => props.theme.colorGreyDark};
    `}

  ${(props) =>
    props.colorTheme === `backgroundImage` &&
    css`
      color: ${(props) => props.theme.colorWhite};
    `}

  @media ${(props) => props.theme.xsmallDown} {
    p {
      opacity: 0.7;
    }
  }
`

const GridWrap = styled.div`
  position: relative;
  z-index: 2;
`

const Bg = styled.div.attrs({ ariaHidden: true })`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const TextBlock = ({
  colorTheme,
  data: {
    acf: { title, text, linkUrl, linkText, backgroundImage, titleRows },
  },
}) => {
  const hasBgImg = backgroundImage && backgroundImage.localFile

  if (!colorTheme && hasBgImg) {
    colorTheme = `backgroundImage`
  }

  return (
    <Container colorTheme={colorTheme}>
      <GridWrap>
        <Grid>
          <Row
            pullY={hasBgImg ? 6 : 4}
            mq={{ xsmallDown: { pullY: hasBgImg ? 30 : 6 } }}
          >
            <Cell size={8} mq={{ xsmallDown: { size: 19 } }}>
              <h2
                className="styled-h4"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Cell>

            <Cell
              size={10}
              pushX={4}
              mq={{ xsmallDown: { size: 23, pushX: 0 } }}
              className="styled styled--large-type"
            >
              <p dangerouslySetInnerHTML={{ __html: text }} />

              {linkUrl && linkUrl.length > 0 && (
                <p>
                  <StoreConsumer>
                    {({ translations }) => (
                      <Link to={linkUrl} className="styled-a">
                        {linkText || translations[`read_more`]}
                      </Link>
                    )}
                  </StoreConsumer>
                </p>
              )}
            </Cell>
          </Row>
        </Grid>
      </GridWrap>

      {backgroundImage && backgroundImage.localFile && (
        <Bg>
          <Img
            fluid={backgroundImage.localFile.childImageSharp.fluid}
            objectPosition="center top"
            objectFit="cover"
            style={{
              width: `100%`,
              height: `100%`,
              display: `block`,
            }}
            alt={title}
          />
        </Bg>
      )}
    </Container>
  )
}

TextBlock.propTypes = {
  colorTheme: PropTypes.string,
  data: PropTypes.object.isRequired,
}

export default TextBlock
