const rtlDirectionByHtmlLang = () => `
  html[lang="ar"] &,
  html[lang="he"] &,
  html[lang="dv"] &,
  html[lang="ur"] &,
  html[lang="fa"] &,
  html[lang="ku"] & {
    direction: rtl;
  }
`

export { rtlDirectionByHtmlLang }
