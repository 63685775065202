import React from "react"
import styled, { css } from "styled-components"
import { Base64 } from "js-base64"

import breakpoints from "../../theme/sections/mq"

const offset = 0.35
const chunk = 4.347
const mobileBP = `xsmallDown`

const Container = styled.div`
  ${`` /* outline: 1px solid blue; */}
  width: 100%;
  height: 0;
  margin: 0 -${offset}%;
  padding-top: ${(props) => (props.size - 1) * chunk + offset * 2}%;
  position: relative;

  @media ${(props) => props.theme[mobileBP]} {
    padding-top: ${(props) => (props.size - 1) * 12.95 + 1.08 * 2}%;
  }

  ${(props) =>
    props.mq &&
    Object.entries(props.mq).map(
      ([key, val]) => css`
        @media ${(props) => props.theme[key]} {
          ${val.size !== undefined &&
          breakpoints[key] > breakpoints[mobileBP] &&
          css`
            padding-top: ${(val.size - 1) * chunk + offset * 2}%;
          `}

          ${val.size !== undefined &&
          breakpoints[key] <= breakpoints[mobileBP] &&
          css`
            padding-top: ${(val.size - 1) * 12.95 + 1.08 * 2}%;
          `}
        }
      `
    )}

  ${(props) =>
    props.dots &&
    css`
      &::before {
        ${`` /* outline: 1px solid green; */}
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-right: ${offset * 2}%;
        background-size: ${chunk}%;
        background-origin: content-box;
        background-image: url("data:image/svg+xml;base64,${(props) =>
          Base64.encode(`
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
            <circle
              cx="4"
              cy="4"
              r="4"
              fill="${props.dots.color}"
              fill-opacity="${props.dots.opacity || 1}"
            />
          </svg>
      `)}");

        @media ${(props) => props.theme[mobileBP]} {
          padding-right: 0;
          background-size: 12.95%;
        }
      }
    `}
`

const Spacer = (props) => <Container {...props} />

export default Spacer
