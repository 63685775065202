import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { rtlDirectionByHtmlLang } from "../../utils/css"

const Container = styled.div`
  ${rtlDirectionByHtmlLang()};

  background-color: ${(props) => props.theme.colorGreyDark};

  ${(props) =>
    props.colorTheme === `white` &&
    css`
      background-color: ${(props) => props.theme.colorWhite};
    `}
`

const CellAside = styled(Cell)`
  @media ${(props) => props.theme.smallDown} {
    display: none;
  }
`

const Aside = styled.aside`
  li + li {
    margin-top: 1em;
  }
`

const Intro = ({
  colorTheme,
  data: {
    acf: { text, highlights, body },
  },
}) => {
  if (!text && !highlights && !body) return null

  return (
    <Container colorTheme={colorTheme}>
      <Grid>
        <Row pullY={body ? 1 : 3}>
          {(text || body) && (
            <Cell
              size={11}
              mq={{ smallDown: { size: 23 } }}
              className="styled styled--large-type"
              dangerouslySetInnerHTML={{ __html: text || body }}
            />
          )}

          {highlights && (
            <CellAside
              size={5}
              pushX={text || body ? 5 : 0}
              mq={{
                smallDown: { size: 23, pushX: 0, pushY: text || body ? 1 : 0 },
              }}
            >
              <Aside>
                <ul>
                  {highlights.map(({ text }, i) => (
                    <li key={i} className="styled-p">
                      {text}
                    </li>
                  ))}
                </ul>
              </Aside>
            </CellAside>
          )}
        </Row>

        {text && body && (
          <Row pullY={3}>
            <Cell
              size={11}
              mq={{ smallDown: { size: 23 } }}
              className="styled styled--large-type"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </Row>
        )}
      </Grid>
    </Container>
  )
}

Intro.propTypes = {
  colorTheme: PropTypes.string,
  data: PropTypes.object.isRequired,
}

export default Intro
