import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Spacer from "../dotgrid/spacer"
import Blurb from "./blurb"

const dots = { color: `#000`, opacity: 0.2 }

const Container = styled.section`
  background-color: ${(props) => props.theme.colorGreyLight};
`

const Title = styled.h2``

const Blurbs = ({
  data: {
    acf: { title, titleLevel, blurbs },
  },
}) => {
  if (!blurbs || !blurbs.length) return null

  return (
    <Container>
      <Grid noBottomPadding>
        <Row pullY={1} mq={{ xsmallDown: { pullY: 2 } }}>
          <Cell size={23}>
            {title && (
              <Title
                as={titleLevel}
                className="styled-h3 styled"
                dangerouslySetInnerHTML={
                  typeof title !== `object`
                    ? {
                        __html: title,
                      }
                    : undefined
                }
              >
                {typeof title === `object` ? title : undefined}
              </Title>
            )}
          </Cell>
        </Row>
      </Grid>

      <Grid noTopPadding noBottomPadding>
        <Spacer dots={dots} size={4} mq={{ xsmallDown: { size: 2 } }} />
      </Grid>

      <Grid noTopPadding noBottomPadding>
        <Row
          pushY={1.5}
          pullY={1.5}
          mq={{ xsmallDown: { pushY: 3, pullY: 3 } }}
        >
          {blurbs.map((blurb, i) => (
            <Cell
              key={i}
              size={7}
              pushX={i % 3 !== 0 && 1}
              mq={{
                smallDown: {
                  size: 23,
                  pushX: 0,
                  pushY: i !== 0 && 3,
                },
              }}
            >
              <Blurb data={blurb} />
            </Cell>
          ))}
        </Row>
      </Grid>

      <Grid noTopPadding>
        <Spacer dots={dots} size={2} />
      </Grid>
    </Container>
  )
}

Blurbs.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Blurbs
